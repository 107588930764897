<template>
  <b-card>
    <b-row>
      <!-- User Info: Left col -->
      <b-col
        cols="21"
        xl="2"
        class="d-flex justify-content-between flex-column"
      >
        <!-- User Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">
          <b-avatar
            :src="resolveAvatarLink(userData.first_name, userData.last_name)"
            :text="avatarText(userData.first_name)"
            size="104px"
            rounded
          />
        </div>
      </b-col>

      <!-- Right Col: Table -->
      <b-col cols="14" xl="5">
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <span class="font-weight-bold">Prénom</span>
            </th>
            <td class="pb-50">
              {{ userData.first_name }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <span class="font-weight-bold">Nom</span>
            </th>
            <td class="pb-50">
              {{ userData.last_name }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <span class="font-weight-bold">Email</span>
            </th>
            <td class="pb-50">
              {{ userData.email }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <span class="font-weight-bold">Mobile</span>
            </th>
            <td class="pb-50">
              {{ userData.mobile }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <span class="font-weight-bold">Genre</span>
            </th>
            <td class="pb-50">
              {{ userData.gender }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <span class="font-weight-bold">Emploi</span>
            </th>
            <td class="pb-50">
              {{ userData.job_role }}
            </td>
          </tr>
        </table>
      </b-col>

      <!-- Right Col: Table -->
      <b-col cols="14" xl="5">
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <span class="font-weight-bold">Role</span>
            </th>
            <td class="pb-50">
              {{ userData.role }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <span class="font-weight-bold">Statut</span>
            </th>
            <td class="pb-50">
              {{ userData.status }}
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BAvatar, BRow, BCol } from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";
import { resolveAvatarLink } from "@core/utils/utils";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BAvatar,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {
      resolveAvatarLink,
      avatarText,
    };
  },
};
</script>

<style></style>
