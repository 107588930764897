import store from "@/store";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function useUsersDetail() {
  // Use toast
  const toast = useToast();

  const fetchUserByID = (user) => {
    const userID = user.value.ID;
    store
      .dispatch("app-user/fetchUserByID", { userID })
      .then((response) => {
        user.value = response.data;
      })
      .catch(() => {
        toast(
          {
            component: ToastificationContent,
            props: {
              title: "Error fetching user detail",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          },
          { position: "top-center" }
        );
      });
  };

  return {
    fetchUserByID,
  };
}
