<template>
  <div>
    <b-row class="mt-1">
      <b-col class="text-right">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          :to="{ name: 'user-edit', params: { id: userData.ID } }"
        >
          <feather-icon icon="PlusIcon" class="mr-50" />
          <span>Editer Utilisateur</span>
        </b-button>
      </b-col>
    </b-row>

    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="userData === undefined">
      <h4 class="alert-heading">Error fetching user data</h4>
      <div class="alert-body">
        No user found with this user id. Check
        <b-link class="alert-link" :to="{ name: 'user-list' }">
          User List
        </b-link>
        for other users.
      </div>
    </b-alert>

    <template v-if="userData">
      <!-- First Row -->
      <b-row class="match-height mt-2">
        <b-col cols="12" xl="8" lg="8" md="7">
          <user-detail-info-card :user-data="userData" />
        </b-col>
        <b-col cols="12" md="6" xl="4" lg="4">
          <user-detail-company-card
            v-if="userData.company"
            :user-company="userData.company"
          />
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import { PageBoxed } from "@/views/utils/PageBoxed.js";
import { useRouter } from "@core/utils/utils";
import { ref, onUnmounted } from "@vue/composition-api";
import Ripple from "vue-ripple-directive";
import { BRow, BCol, BAlert, BLink, BButton } from "bootstrap-vue";
import UserDetailCompanyCard from "./UserDetailCompanyCard.vue";
import UserDetailInfoCard from "./UserDetailInfoCard.vue";
import useUsersDetail from "./useUsersDetail";
import userStoreModule from "../userStoreModule";
import store from "@/store";

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BAlert,
    BLink,

    // Local Components
    UserDetailCompanyCard,
    UserDetailInfoCard,
  },
  mixins: [PageBoxed],
  directives: {
    Ripple,
  },
  setup() {
    const userData = ref({});

    const USER_APP_STORE_MODULE_NAME = "app-user";

    const { fetchUserByID } = useUsersDetail();

    // Pull userID from router
    const { route } = useRouter();
    const userID = route.value.params.id;

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    userData.value.ID = userID;
    fetchUserByID(userData);

    return {
      userData,
    };
  },
};
</script>
