<template>
  <b-card class="itemgrid-card" no-body>
    <div class="item-img text-center mt-2">
      <b-link :to="{ name: 'customer-detail', params: { id: userCompany.ID } }">
        <b-img
          :alt="`${userCompany.name}-${userCompany.ID}`"
          fluid
          class="card-img-company"
          :src="`${publicPath}` + `images/customers/` + `${userCompany.logo}`"
        />
      </b-link>
    </div>
    <!-- Product Details -->
    <b-card-body>
      <h4 class="item-name">
        <b-link
          class="text-body"
          :to="{ name: 'customer-detail', params: { id: userCompany.ID } }"
        >
          {{ userCompany.name }}
        </b-link>
      </h4>
      <b-card-text class="item-description">
        {{ userCompany.description }}
      </b-card-text>
    </b-card-body>
  </b-card>
</template>

<script>
import { BCard, BLink, BCardBody, BCardText, BImg } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BCard,
    BLink,
    BCardBody,
    BCardText,
    BImg,
  },
  directives: {
    Ripple,
  },
  props: {
    userCompany: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-itemgrid.scss";
</style>
